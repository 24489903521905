import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Plus from '../svg/icons/plus.svg';
import Minus from '../svg/icons/minus.svg';
import {
  container,
  header,
  heading,
  answerText,
} from './FAQ.module.css';

const propTypes = {
  question: PropTypes.node.isRequired,
  answer: PropTypes.node.isRequired,
};

const FAQ = (props) => {
  const { question, answer } = props;

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => setVisible(!visible);

  return (
    <button type="button" onClick={toggleVisible} className={container}>
      <header className={header}>
        {visible ? <Minus width={20} height={20} /> : <Plus width={20} height={20} />}
        <p className={heading}>{question}</p>
      </header>
      {visible ? <p className={answerText}>{answer}</p> : null}
    </button>
  );
};

FAQ.propTypes = propTypes;

export default FAQ;
