import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import faq from '../images/faq.jpg';
import FAQComponent from '../components/FAQ';

const FAQ = ({ data }) => {
  const { wpWebdata } = data;
  const attributes = wpWebdata;
  const { allWpFaq } = data;

  return (
    <Layout pageTitle="FAQ" copyright={attributes.shibuya.copyright}>
      <Hero backgroundImage={faq} attributes={attributes} />
      <article className="article">
        <h1>F.A.Q.</h1>
        {map(
          allWpFaq.nodes,
          (item, index) => (
            <FAQComponent
              key={index}
              question={item.frequentQuestion.question}
              answer={item.frequentQuestion.answer}
            />
          ),
        )}
      </article>
    </Layout>
  );
};

export default FAQ;

FAQ.propTypes = {
  data: PropTypes.shape({
    wpWebdata: PropTypes.shape({
      title: PropTypes.string,
      shibuya: PropTypes.shape({
        titleHome: PropTypes.string,
        titleAboutUs: PropTypes.string,
        titleMenu: PropTypes.string,
        titleReserve: PropTypes.string,
        titleFaq: PropTypes.string,
        titleContact: PropTypes.string,
        copyright: PropTypes.string,
      }),
    }),
    allWpFaq: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          frequentQuestion: PropTypes.arrayOf(
            PropTypes.shape({
              question: PropTypes.string,
              answer: PropTypes.string,
              language: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  }).isRequired,
};

export const query = graphql`
query ($language: String!) {
  wpWebdata(title: {eq: $language}) {
    title
    shibuya {
      titleHome
      titleAboutUs
      titleMenu
      titleReserve
      titleFaq
      titleContact
      copyright
    }
  }
  allWpFaq(filter: {frequentQuestion: {language: {eq: $language}}}
    sort: {fields: date, order: ASC}
    ) {
    nodes {
      title
      frequentQuestion {
        question
        answer
        language
      }
    }
  }
}
`;
